import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import CircularProgress from '@mui/material/CircularProgress';
import ProfileIcon from '@mui/icons-material/PersonRounded';
import FavoriteFilledIcon from '@mui/icons-material/FavoriteRounded';
import FavoriteIcon from '@mui/icons-material/FavoriteBorderRounded';
import FollowerIcon from '@mui/icons-material/PeopleRounded';
import LogoutIcon from '@mui/icons-material/LogoutRounded';
import PostsIcon from '@mui/icons-material/FeedRounded';
import { layoutContainerStyle, contentContainer } from '../styles';
import NavbarContainer from '../container/NavbarContainer';
import { Post } from './Post';
import { loadMoreStyle, loadMoreContainer, loadingStyle } from './Dashboard';
import Header from './Header';
import { getLoggedInUserTag } from '../store/reducer/login';

const entryStyle = css`
  display: flex;
  align-items: center;
  padding: 1vh;
  justify-content: space-between;
  overflow: hidden;
`;

const profileIconStyle = css`
  height: 2.5vh !important;
  width: 2.5vh !important;
  background-color: #d50000;
  border-radius: 50%;
  color: white;
  padding: 0.25vh;
  cursor: pointer;
`;

const userStyle = css`
  color: #ffffff99;
  padding: 0vh 1.5vh;
  font-size: 1.5vh;
  flex-grow: 1;
  font-weight: 100;
  overflow: hidden;
  overflow-wrap: break-word;
`;

const displayNameStyle = css`
  color: #ffffff;
  font-size: 2.5vh;
  line-height: 2.5vh;
`;

const followerStyle = css`
  padding: 0vh 1.5vh;
  color: #ffffff;
  font-size: 1.2vh;
  margin: 0.5vh 0;
  display: flex;
  align-items: center;
`;
const dateStyle = css`
  font-size: 1.25vh;
`;

const followStyle = css`
  padding: 0.5vh;
  height: 3vh !important;
  width: 3vh !important;
  border-radius: 50%;
  cursor: pointer;
  color: #ffffff;
  align-items: center;
`;

const followIconStyles = css`
  width: 2vh !important;
  height: 2vh !important;
  margin-right: 0.25vh;
`;

const postsStyle = css`
  padding-top: 3vh;
  max-width: 100vh;
  margin: auto;
  margin-top: 0.5vh;
  margin-bottom: 7vh;
`;

const positionRelativeStyle = css`
  position: relative;
`;

const dateOptions: any = {
  month: 'short',
  day: '2-digit',
  year: 'numeric',
};

type ProfilePageType = {
  fetchProfilePage: (userTag: string) => void;
  account: { userTag: string; displayName: string; registrationDate: string };
  posts: any[];
  followerAmount: number;
  isFollower: boolean;
  startFollow: (userTag: string) => void;
  startUnfollow: (userTag: string) => void;
  fetchProfileMorePosts: (userTag: string) => void;
  isLoading: boolean;
  goto: any;
  urlParams: any | undefined;
  like: (postId: string) => void;
  logout: () => void;
};
export const ProfilePage = ({
  fetchProfilePage,
  account,
  posts,
  followerAmount,
  isFollower,
  startFollow,
  startUnfollow,
  fetchProfileMorePosts,
  isLoading,
  goto,
  urlParams,
  logout,
  like,
}: ProfilePageType) => {
  useEffect(() => {
    const userTag = urlParams?.userTag;
    if (userTag) {
      fetchProfilePage(userTag);
    }
  }, [urlParams]);

  if (!account) {
    return (
      <div css={layoutContainerStyle}>
        <Header goto={goto} />
        <NavbarContainer />
      </div>
    );
  }

  const followBtnClick = () => {
    if (isFollower) {
      startUnfollow(account.userTag);
    } else {
      startFollow(account.userTag);
    }
  };

  const isOwnAccount = getLoggedInUserTag() === account.userTag;

  return (
    <div css={layoutContainerStyle}>
      <Header goto={goto} />
      <NavbarContainer />
      <div css={[contentContainer, positionRelativeStyle]}>
        <div css={entryStyle}>
          <ProfileIcon css={profileIconStyle} />
          <div css={userStyle}>
            <div css={displayNameStyle}>{account.displayName}</div>@
            {account.userTag}
          </div>
          {isOwnAccount ? (
            <LogoutIcon css={followStyle} onClick={logout} />
          ) : isFollower ? (
            <FavoriteFilledIcon css={followStyle} onClick={followBtnClick} />
          ) : (
            <FavoriteIcon css={followStyle} onClick={followBtnClick} />
          )}
        </div>
        <div css={[userStyle, dateStyle]}>
          Mitglied seit{' '}
          {new Intl.DateTimeFormat('default', dateOptions).format(
            new Date(account.registrationDate),
          )}
        </div>
        <div css={followerStyle}>
          <FollowerIcon css={followIconStyles} />
          {followerAmount || 0}
          {/* <PostsIcon css={[followIconStyles, { 'margin-left': '1vh' }]} />
          {posts ? posts.length : 0} */}
        </div>

        <div css={postsStyle}>
          {posts &&
            posts.map((post) => (
              <Post
                key={post._id}
                _id={post._id}
                displayName={post.displayName}
                userTag={post.userTag}
                date={new Date(post.created).toISOString()}
                text={post.text}
                amountsLikes={post.amountsLikes}
                amountsDislikes={post.amountsDislikes}
                amountsComments={post.amountsComments}
                shared={[]}
                onLike={() => like(post._id)}
                isLiked={post.isLiked}
                isDisliked={post.isDisliked}
                image={post.images && post.images[0]}
                goto={goto}
              />
            ))}
          <div
            css={[loadMoreContainer, !isLoading && loadMoreStyle]}
            onClick={() => fetchProfileMorePosts(account.userTag)}
          >
            {isLoading ? (
              <CircularProgress css={loadingStyle} />
            ) : (
              `${posts && posts.length > 0 ? 'Weitere ' : ''}Beiträge laden`
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
