import React, { useState } from 'react';
import { css } from '@emotion/react';
import ProfileIcon from '@mui/icons-material/PersonRounded';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteBorderRounded';
import FavoriteFilledIcon from '@mui/icons-material/FavoriteRounded';
import { getLoggedInUserTag } from '../store/reducer/login';
import CommentIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import Comments from './Comments';
import RenderedText from './RenderedText';
import { DateTime } from 'luxon';

const postContainer = css`
  background-color: #00000066;
  margin-bottom: 2vh;
  overflow: hidden;
`;

const dateStyle = css`
  padding: 0 1vh 1vh 1vh;
  color: #ffffff99;
  font-size: 1.2vh;
`;

const textStyle = css`
  padding: 0.25vh 1vh;
  color: #ffffff;
  font-weight: 100;
  font-size: 1.5vh;
  color: #cccccc;

  span {
    font-weight: bold;
    color: #ffffff;
  }
`;

const imageStyle = css`
  width: 100%;
  margin-top: 0.5vh;
`;

export const headlineStyle = css`
  display: flex;
  align-items: center;
  padding: 1vh;
  padding-bottom: 0.5vh;
`;

const profileIconStyle = css`
  height: 2.5vh !important;
  width: 2.5vh !important;
  background-color: #d50000;
  border-radius: 50%;
  color: white;
  padding: 0.25vh;
  cursor: pointer;
`;

export const userTagStyle = css`
  color: #ffffff;
  font-weight: 100;
  padding-left: 1vh;
  font-size: 2vh;
  cursor: pointer;
  word-break: break-all;
`;

const reactionBarStyle = css`
  padding: 1vh;
  display: flex;
  align-items: center;
`;

const reactionIconStyle = css`
  height: 2vh !important;
  width: 2vh !important;
  color: #ffffff;
  padding: 0.25vh;
  border-radius: 50%;
  cursor: pointer;
  color: white;
  :hover {
    background-color: #ffffff33;
  }
`;

const reactionIconDisabledStyle = css`
  color: #ffffff99;
  cursor: auto;
  :hover {
    background-color: transparent;
  }
`;

const likeCountStyle = css`
  color: #ffffff99;
  font-size: 1.2vh;
`;

export type PostType = {
  _id: string;
  displayName: string;
  userTag: string;
  date: string;
  text: string;
  image?: string;
  amountsLikes: number;
  amountsDislikes: number;
  amountsComments: number;
  shared: string[];
  goto: (url: string, params: any) => void;
  onLike?: () => void;
  onDislike?: () => void;
  isLiked?: boolean;
  isDisliked?: boolean;
};

export const Post = ({
  _id,
  displayName,
  userTag,
  date,
  text,
  image,
  amountsLikes,
  amountsDislikes,
  amountsComments,
  shared,
  goto = () => {},
  onLike = () => {},
  onDislike = () => {},
  isLiked,
  isDisliked,
}: PostType) => {
  const isOwnPost = getLoggedInUserTag() === userTag;
  const [showComments, setShowComments] = useState(false);
  const onProfileSelect = () => goto(`/profilepage`, { userTag });
  return (
    <div css={postContainer}>
      <div css={headlineStyle}>
        <ProfileIcon css={profileIconStyle} onClick={onProfileSelect} />
        <div css={userTagStyle} onClick={onProfileSelect}>
          @{userTag}
        </div>
      </div>
      <div>
        {image && <img src={image} css={imageStyle} />}
        <div css={textStyle}>
          <span css={{ cursor: 'pointer' }} onClick={onProfileSelect}>
            @{userTag}
          </span>
          <RenderedText text={text} goto={goto} />
        </div>
        <span css={dateStyle}>{DateTime.fromISO(date).toRelative()}</span>
        <div css={reactionBarStyle}>
          {isLiked ? (
            <FavoriteFilledIcon
              css={[reactionIconStyle, isOwnPost && reactionIconDisabledStyle]}
              onClick={() => (isOwnPost ? {} : onLike())}
            />
          ) : (
            <FavoriteOutlinedIcon
              css={[reactionIconStyle, isOwnPost && reactionIconDisabledStyle]}
              onClick={() => (isOwnPost ? {} : onLike())}
            />
          )}
          <div css={likeCountStyle}>{amountsLikes}</div>
          <CommentIcon
            css={reactionIconStyle}
            onClick={() => setShowComments(true)}
          />
          <div css={likeCountStyle}>{amountsComments}</div>
        </div>
        {showComments && (
          <Comments
            post={_id}
            onClose={() => setShowComments(false)}
            goto={goto}
          />
        )}
      </div>
    </div>
  );
};
