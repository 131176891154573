import { pathOr } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  startAdminPlayerDetails,
  startAdminToggleDisableSerial,
  startAdminToggleMultiAccAccCheck,
  startAdminTpAdminBase,
} from '../../../actions';
import { appsAdminPlayerDetailsP } from '../../../redux/reducer/apps/admin/player';
import { PlayerType } from './types/player';
import { translate } from '../../../utils/translate';
import CachedIcon from '@mui/icons-material/Cached';
import RotateLeft from '@mui/icons-material/RotateLeft';
RotateLeft;
import { Button } from '@mui/material';
import { css } from '@emotion/react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { DateTime } from 'luxon';
import { PlayerIds } from './playerhelpers/Ids';
import { BankAccounts } from './playerhelpers/BankAccounts';
import { Vehicles } from './playerhelpers/Vehicles';
import { TelNumbers } from './playerhelpers/TelNumbers';
import { Inventory } from './playerhelpers/Inventory';
import { Multiaccount } from './playerhelpers/Multiaccount';
import { MultiaccountB } from './playerhelpers/MultiaccountB';
import { FactoryOwner } from './playerhelpers/FactoryOwner';
import { FactoryEmployment } from './playerhelpers/FactoryEmployment';
import { Apartments } from './playerhelpers/Apartments';
import { Deaths } from './playerhelpers/Deaths';
import { Tickets } from './playerhelpers/Tickets';
import { Notes } from './playerhelpers/Notes';
import { Warnings } from './playerhelpers/Warnings';
import { Bans } from './playerhelpers/Bans';
import { Actions } from './playerhelpers/Actions';
import { detailContainerStyle } from './styles';
import { Middlebar } from '../../sidebars/Middlebar';
import { FaPaw } from 'react-icons/fa';
import { useAuthentication } from '../../../utils/authentication';

const bannedStyle = css`
  color: #fd4848 !important;
`;
const containerStyle = css`
  display: flex;
  margin: -1.5rem;
  height: calc(100% + 3rem);
`;
const mainContainerStyle = css`
  padding: 0vh 1vh;
`;

const menu = [
  {
    id: 'general',
    title: 'Allgemein',
    icon: <FaPaw />,
    excerpt: 'Die wichtigsten Punkte auf einen Blick.',
  },
  {
    id: 'actions',
    title: 'Aktionen',
    icon: <FaPaw />,
    excerpt: 'Aktionen beim Spieler',
  },
  {
    id: 'vehicles',
    title: 'Fahrzeuge',
    icon: <FaPaw />,
    excerpt: 'Sport, Luxus, Utility',
  },
  {
    id: 'notes',
    title: 'Notizen',
    icon: <FaPaw />,
    excerpt: 'Hinweise zum Spieler',
  },
  {
    id: 'warnings',
    title: 'Verwarnungen',
    icon: <FaPaw />,
    excerpt: 'Dududu',
  },
  {
    id: 'bans',
    title: 'Bans',
    icon: <FaPaw />,
    excerpt: 'Ausschlüsse',
  },
  {
    id: 'deaths',
    title: 'Tode',
    icon: <FaPaw />,
    excerpt: 'Ins Gras beissen',
  },
  {
    id: 'factories',
    title: 'Fabrik Besitz',
    icon: <FaPaw />,
    excerpt: 'Business Man/Woman',
  },
  {
    id: 'employments',
    title: 'Anstellung',
    icon: <FaPaw />,
    excerpt: 'Eingestellt bei ...',
  },
  {
    id: 'inventory',
    title: 'Inventar',
    icon: <FaPaw />,
    excerpt: 'Habseligkeiten',
  },
  {
    id: 'multiA',
    title: 'Multiaccount A',
    icon: <FaPaw />,
    excerpt: 'auto kick',
  },
  {
    id: 'multiB',
    title: 'Multiaccount B',
    icon: <FaPaw />,
    excerpt: 'auto kick',
  },
  {
    id: 'ids',
    title: 'Ids',
    icon: <FaPaw />,
    excerpt: 'Interne Erkennung',
  },
  {
    id: 'bank',
    title: 'Bank Konten',
    icon: <FaPaw />,
    excerpt: 'Kontoübersicht',
  },
  {
    id: 'tel',
    title: 'Tel. Nr.',
    icon: <FaPaw />,
    excerpt: 'Handyverträge',
  },
  {
    id: 'apartments',
    title: 'Apartments',
    icon: <FaPaw />,
    excerpt: 'Obdachlosenheim ???',
  },
  {
    id: 'tickets',
    title: 'Tickets',
    icon: <FaPaw />,
    excerpt: 'Strafzettel',
  },
];

type PlayerViewType = {
  _id: string;
  openVehicle: (_id: string, numberPlate: string) => void;
  openFactory: (_id: string, label: string) => void;
  openApartment: (_id: string, label: string) => void;
  openBankAccount: (_id: string, label: string) => void;
};
export const PlayerView = ({
  _id,
  openVehicle,
  openFactory,
  openApartment,
  openBankAccount,
}: PlayerViewType) => {
  const dispatch = useDispatch();
  const [hasRight] = useAuthentication();
  const player = useSelector<any, PlayerType | undefined>(
    pathOr(undefined, appsAdminPlayerDetailsP)
  );
  const [selectedMenu, setSelectedMenu] = useState('general');

  useEffect(() => {
    if (_id) {
      dispatch(startAdminPlayerDetails(_id));
    }
  }, [_id]);

  const refresh = () => {
    dispatch(startAdminPlayerDetails(_id));
  };

  if (!player) {
    return null;
  }

  const tpAdminBase = () => {
    if (!player.online) {
      dispatch(startAdminTpAdminBase(_id));
    }
  };
  const toggleDisableSerial = () => {
    if (!player.online) {
      dispatch(startAdminToggleDisableSerial(_id));
    }
  };
  const toggleMultiAccAccCheck = () => {
    if (!player.online) {
      dispatch(startAdminToggleMultiAccAccCheck(_id));
    }
  };

  return (
    <div css={containerStyle}>
      <div className="flex-none w-60 h-full overflow-auto">
        <Middlebar
          items={menu}
          selected={selectedMenu}
          select={setSelectedMenu}
        />
      </div>
      <div css={mainContainerStyle} className="h-full w-full overflow-auto">
        {selectedMenu === 'general' && (
          <>
            <div css={[detailContainerStyle]}>
              <h2 css={[player.banned && bannedStyle]}>
                Spielerdetails: {player.name} (
                {player.online ? 'online' : 'offline'}){' '}
                {player.banned && '- gebannt'}
              </h2>

              <Button variant="outlined" onClick={refresh}>
                Aktualisieren <CachedIcon />
              </Button>
            </div>

            <div css={detailContainerStyle}>
              <h2>Allgemein</h2>

              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Position</TableCell>
                    <TableCell>
                      {(player.position?.x ?? 0).toFixed(2)}{' '}
                      {(player.position?.y ?? 0).toFixed(2)}{' '}
                      {(player.position?.z ?? 0).toFixed(2)}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        onClick={tpAdminBase}
                        disabled={player.online}
                      >
                        Zur Admin Base setzen <RotateLeft />
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Dimension</TableCell>
                    <TableCell>{player.dimension}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Forum Name</TableCell>
                    <TableCell>{player.forumname}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Forum Id</TableCell>
                    <TableCell>{player.forumid}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Spielstunden</TableCell>
                    <TableCell>{player.paydays}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Geschlecht</TableCell>
                    <TableCell>
                      {player.sex === 0 ? 'Weiblich' : 'Männlich'}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Bargeld</TableCell>
                    <TableCell>${player.money}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Fraktion</TableCell>
                    <TableCell>{player.team?.label ?? 'Kein'}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Multiaccounterkennung</TableCell>
                    <TableCell>
                      {player.disableSerial ? 'Deaktiviert' : 'Aktiviert'}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        onClick={toggleDisableSerial}
                        disabled={player.online}
                      >
                        {player.disableSerial ? 'Aktivieren' : 'Deaktivieren'}
                      </Button>
                    </TableCell>
                  </TableRow>
                  {hasRight('admin.player.accountcheck') && (
                    <TableRow>
                      <TableCell>Erlaube weiteren Char</TableCell>
                      <TableCell>{player.mahExempt ? 'Ja' : 'Nein'}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          onClick={toggleMultiAccAccCheck}
                          disabled={player.online}
                        >
                          {!player.mahExempt ? 'Aktivieren' : 'Deaktivieren'}
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>Lizenzen</TableCell>
                    <TableCell>
                      {player.licenses
                        .map((lic) => translate(lic.hash))
                        .join(', ')}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Registriert</TableCell>
                    <TableCell>
                      {DateTime.fromJSDate(new Date(player.visumStart))
                        .setLocale('de')
                        .toFormat('dd.LL.yy, HH:mm')}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Whitelist</TableCell>
                    <TableCell>
                      {player.whitelist?.passed ? 'JA' : 'NEIN'}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </>
        )}

        {selectedMenu === 'actions' && (
          <Actions _id={_id} isOnline={player.online} />
        )}
        {selectedMenu === 'ids' && <PlayerIds _id={_id} />}
        {selectedMenu === 'bank' && (
          <BankAccounts _id={_id} openBankAccount={openBankAccount} />
        )}
        {selectedMenu === 'vehicles' && (
          <Vehicles _id={_id} openVehicle={openVehicle} />
        )}
        {selectedMenu === 'tel' && <TelNumbers _id={_id} />}
        {selectedMenu === 'inventory' && (
          <Inventory _id={_id} isOnline={player.online} />
        )}
        {selectedMenu === 'multiA' && <Multiaccount _id={_id} />}
        {selectedMenu === 'multiB' && <MultiaccountB _id={_id} />}
        {selectedMenu === 'factories' && (
          <FactoryOwner _id={_id} openFactory={openFactory} />
        )}
        {selectedMenu === 'employments' && (
          <FactoryEmployment _id={_id} openFactory={openFactory} />
        )}
        {selectedMenu === 'apartments' && (
          <Apartments _id={_id} openApartment={openApartment} />
        )}
        {selectedMenu === 'deaths' && <Deaths _id={_id} />}
        {selectedMenu === 'tickets' && <Tickets _id={_id} />}
        {selectedMenu === 'notes' && <Notes _id={_id} />}
        {selectedMenu === 'warnings' && <Warnings _id={_id} />}
        {selectedMenu === 'bans' && <Bans _id={_id} />}
      </div>
    </div>
  );
};
