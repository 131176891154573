import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { TextField } from '@mui/material';
import format from 'date-fns/format';
import de from 'date-fns/locale/de';
import { pathOr } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFractionDutyListSearch,
  startFractionDutyListFetch,
} from '../../../actions/fraction';
import {
  appsFractionDutyListP,
  appsFractionDutyListSearchP,
} from '../../../redux/reducer/apps/fraction';
import { FractionDutyListData } from './types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import { Pagination } from '@mui/material';
import Headline from '../../Headline';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import SendIcon from '@mui/icons-material/SendRounded';
import InputAdornment from '@mui/material/InputAdornment';
import { Red, Green } from '../../../configs/styles/colors';

const TIME_OPTIONS = {
  locale: de,
};

const inputStyle = css`
  width: 100%;
  max-width: 35vh;
  display: flex !important;
`;
const tableStyle = css`
  .MuiTableCell-root {
    font-size: 1.6vh !important;
  }
`;
const noResultsStyle = css`
  margin-top: 2vh;
`;
const varFontColor = (color: string) => css`
  color: ${color};
`;

export const DutyList = () => {
  const dispatch = useDispatch();
  const data = useSelector<any, null | FractionDutyListData>(
    pathOr(null, appsFractionDutyListP)
  );
  const search = useSelector<any, string>(
    pathOr('', appsFractionDutyListSearchP)
  );
  const setSearch = (ev: any) => {
    dispatch(setFractionDutyListSearch(ev.target.value));
  };

  useEffect(() => {
    if (!data) {
      dispatch(startFractionDutyListFetch(search, 1));
    }
  }, []);

  const handleChangePage = (ev: any, page: number) => {
    dispatch(startFractionDutyListFetch(search, page));
  };
  const startSearchFetch = () => {
    dispatch(startFractionDutyListFetch(search, 1));
  };

  return (
    <div>
      <Headline>Stempelkarten</Headline>
      <TextField
        type="text"
        placeholder="Max_Mustermann"
        value={search}
        css={inputStyle}
        onChange={setSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              css={{ cursor: 'pointer' }}
              onClick={startSearchFetch}
            >
              <SendIcon />
            </InputAdornment>
          ),
        }}
      />
      <div>
        {data && data.list.length > 0 ? (
          <Table css={tableStyle}>
            <TableHead>
              <TableRow>
                <TableCell>Datum</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.list.map((entry) => (
                <TableRow key={entry._id}>
                  <TableCell>
                    {format(
                      new Date(entry.date),
                      'd.MM.yyyy HH:mm',
                      TIME_OPTIONS
                    )}
                  </TableCell>
                  <TableCell>{entry.characterName}</TableCell>
                  <TableCell>
                    <span css={varFontColor(entry.onDuty ? Green : Red)}>
                      {entry.onDuty ? 'Eingestempelt' : 'Ausgestempelt'}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={data.page}
                    count={data.pages}
                    onChange={handleChangePage}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        ) : (
          <div css={noResultsStyle}>Keine Ergebnisse gefunden</div>
        )}
      </div>
    </div>
  );
};
