import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { layoutContainerStyle, contentContainer } from '../styles';
import Navbar from '../container/NavbarContainer';
import { Post } from './Post';
import CircularProgress from '@mui/material/CircularProgress';
import Header from './Header';

const titleStyle = css`
  font-size: 2.25vh;
  letter-spacing: -0.05vh;
  color: white;
  padding: 1vh;
`;

const postsStyle = css`
  max-width: 100vh;
  margin: auto;
  margin-top: 0.5vh;
  margin-bottom: 7vh;
`;

export const loadMoreStyle = css`
  cursor: pointer;

  :hover {
    background: #ffffff33;
    color: #ffffff;
  }
`;

export const loadingStyle = css`
  color: #ffffff !important;
  height: 2vh !important;
  width: 2vh !important;
`;

const noPostsStyle = css`
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding: 1vh 0;
  color: #ffffff99;
  font-weight: 100;
  font-size: 1.25vh;
  margin-bottom: 3vh;
`;

export const loadMoreContainer = css`
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding: 1vh 0;
  color: #ffffff99;
  font-weight: 100;
  font-size: 1.25vh;
`;

export type DashboardType = {
  fetchPosts: () => void;
  posts: any[];
  goto: (route: string, params?: any) => void;
  like: (postId: string) => void;
  dislike: (postId: string) => void;
  fetchMorePosts: (count?: number) => void;
  isLoading: boolean;
  title?: String;
};

export const Dashboard = ({
  fetchPosts,
  posts,
  goto,
  like,
  dislike,
  fetchMorePosts,
  isLoading,
  title,
}: DashboardType) => {
  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <div css={layoutContainerStyle}>
      <Header goto={goto} />
      <Navbar />
      <div css={contentContainer}>
        {title && <div css={titleStyle}>{title}</div>}
        <div css={postsStyle}>
          {posts && posts.length > 0 ? (
            posts.map((post) => (
              <Post
                key={post._id}
                _id={post._id}
                displayName={post.displayName}
                userTag={post.userTag}
                date={new Date(post.created).toISOString()}
                text={post.text}
                amountsLikes={post.amountsLikes}
                amountsDislikes={post.amountsDislikes}
                amountsComments={post.amountsComments}
                shared={[]}
                goto={goto}
                onLike={() => like(post._id)}
                onDislike={() => dislike(post._id)}
                isLiked={post.isLiked}
                isDisliked={post.isDisliked}
                image={post.images && post.images[0]}
              />
            ))
          ) : (
            <div css={noPostsStyle}>Keine Beiträge vorhanden</div>
          )}
          {/* <Post
            displayName="Leogram_Official"
            userTag="Leogram_Official"
            date={new Date()}
            text="Das hier ist ein Post ohne einem Bild. Mal schauen, was sonst noch so kommt :)"
            amountsLikes={10}
            amountsDislikes={2}
            amountsComments={21}
            comments={[]}
            shared={[]}
          />
          <Post
            displayName="Leogram_Official"
            userTag="Leogram_Official"
            date={new Date()}
            text="Testbild"
            image="https://i.gyazo.com/91b2cf6ddf664e1dee2f2191ad863786.jpg"
            amountsLikes={10}
            amountsDislikes={2}
            amountsComments={3}
            comments={[]}
            shared={[]}
          />
          <Post
            displayName="SehrSehrSehrLangerUsername"
            userTag="SehrSehrSehrLangerUsername"
            date={new Date()}
            text="Test mit extremen Werten"
            image="https://i.gyazo.com/91b2cf6ddf664e1dee2f2191ad863786.jpg"
            amountsLikes={10}
            amountsDislikes={2}
            amountsComments={5}
            comments={[]}
            shared={[]}
          />
          <Post
            displayName="Leogram_Official"
            userTag="Leogram_Official"
            date={new Date()}
            text="Das hier ist ein Post ohne einem Bild. Mal schauen, was sonst noch so kommt :)"
            amountsLikes={1}
            amountsDislikes={2}
            amountsComments={1}
            comments={[]}
            shared={[]}
          />
          <Post
            displayName="Leogram_Official"
            userTag="Leogram_Official"
            date={new Date()}
            text="Testbild"
            image="https://i.gyazo.com/91b2cf6ddf664e1dee2f2191ad863786.jpg"
            amountsLikes={10}
            amountsDislikes={2}
            amountsComments={0}
            comments={[]}
            shared={[]}
          />
          <Post
            displayName="Leogram_Official"
            userTag="Leogram_Official"
            date={new Date()}
            text="Testbild"
            image="https://i.gyazo.com/91b2cf6ddf664e1dee2f2191ad863786.jpg"
            amountsLikes={10}
            amountsDislikes={2}
            amountsComments={105}
            comments={[]}
            shared={[]}
          />*/}
          <div
            css={[loadMoreContainer, !isLoading && loadMoreStyle]}
            onClick={() => fetchMorePosts(posts.length)}
          >
            {isLoading ? (
              <CircularProgress css={loadingStyle} />
            ) : (
              `${posts && posts.length > 0 ? 'Weitere ' : ''}Beiträge laden`
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
